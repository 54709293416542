import { useEffect } from "react";

const Confidentiality = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <p>
        <strong>POLITIQUE DE&nbsp;CONFIDENTIALITÉ</strong>
      </p>
      <p>
        <br />
        &nbsp;
      </p>
      <p>
        ePOPI® ne recueille et n’exploite aucune donnée médicale ou de santé
        individuelle.
      </p>
      <p>
        ePOPI® n’utilise pas de cookies en dehors de l’identification de session
        utilisateur (compte d’abonnés).
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Traitement des données personnelles</strong>
        </li>
      </ul>
      <p>
        Lorsque de la création d’un compte individuel sur le site epopi.fr,
        certaines informations personnelles sont requises&nbsp;:
      </p>
      <ul>
        <li>Nom,</li>
        <li>Prénom,</li>
        <li>Login de connexion,</li>
        <li>Adresse e-mail,</li>
        <li>Spécialité d’exercice,</li>
        <li>Établissement/société</li>
        <li>
          Lieu d’exercice (institution, service, adresse, codes postal, ville,
          Pays),
        </li>
      </ul>
      <p>
        La collecte de ces informations nominatives dans le cadre de
        l’abonnement au site est effectuée en conformité avec les dispositions
        légales en vigueur (Loi du 6 janvier 1978 relative à l’informatique, aux
        fichiers et aux libertés) et au RGPD.
      </p>
      <p>
        Les informations et questions transmises à l’aide du formulaire de
        contact (rubrique : nous consulter) sont recueillies uniquement par
        l’éditeur du site, ALINÉA Plus, à l’adresse contact@epopi.fr.
      </p>
      <p>
        Seul l’éditeur a accès aux informations concernant les abonnés.
        Celles-ci ne sont transmises à aucune société ou organisme, en dehors du
        CMIT. La personne responsable de la gestion de ce formulaire de contact,
        des réponses à y apporter en coordination avec le Comité éditorial, le
        Comité scientifique et le CMIT, est le directeur de la publication du
        site www.epopi.fr, Nadège Lefebvre (nadege.lefebvre@alineaplus.fr ).
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Durée de conservation de vos données</strong>
        </li>
      </ul>
      <p>
        Vos données personnelles seront conservées par Alinéa Plus pour une
        durée de 5 ans après votre dernière utilisation du site epopi.fr si vous
        n’avez pas fermé votre compte et un an après la fermeture de votre
        compte. A l’issue de cette période d’archivage de 5 ans, vos données
        seront définitivement détruites.
      </p>
      <p>
        Les personnes abonnées au site disposent d’un droit d’accès,
        d’opposition et de rectification aux informations les concernant,
        qu’elles peuvent exercer à tout moment en contactant l’éditeur du site :
        ALINEA Plus, 8, rue Froidevaux 75014 Paris, tél : 01 46 67 62 53 -
        contact@epopi.fr.
      </p>
      <p>
        La modification ou la suppression interviendra dans les meilleurs délais
        et au plus tard dans les dix jours ouvrés à compter de la date de
        réception de la demande.
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Droit de réclamation auprès de la CNIL</strong>
        </li>
      </ul>
      <p>
        La base de données des abonnés est gérée par le seul éditeur ALINÉA Plus
        dont les fichiers sont déclarés à la CNIL sous le n° 1634522.
      </p>
      <p>
        Vous disposez du droit d’introduire une réclamation auprès de l’autorité
        de contrôle compétente (CNIL) à l’adresse suivante :
      </p>
      <p>CNIL – Service des plaintes</p>
      <p>3 Place de Fontenoy</p>
      <p>TSA 80715 - 75334 PARIS CEDEX 07</p>
      <p>Tél : 01 53 73 22 22</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>Cookies</strong>
        </li>
      </ul>
      <p>
        ePOPI ne dépose de cookies sur le poste de l’utilisateur que si celui-ci
        sollicite l’enregistrement du poste en tant que poste de confiance,
        permettant ainsi une authentification simple pour ses accès ultérieurs.
      </p>
      <p>&nbsp;</p>
      <p>
        <i>Dernière mise à jour : 25 janvier 2025</i>
      </p>
    </div>
  );
};

export default Confidentiality;
