import { Navigate, createBrowserRouter } from "react-router-dom";

import Root from "./root/Root";
import Home from "./home/Home";
import Connect from "./connect/Connect";
import RegistrationSteps from "./registration/RegistrationSteps";
import Settings from "./settings/Settings";
import Categories from "./categories/Categories";
import Sheet from "./sheet/Sheet";
import CustomPage from "./customPage/CustomPage";
import Comity from "./comity/Comity";
import CmitMembers from "./cmitMembers/CmitMembers";
import Subscription from "./subscription/Subscription";
import SubscriptionRecap, {
  loader as subscriptionLoader,
} from "./subscriptionRecap/SubscriptionRecap";
import Abbreviations from "./abbreviations/Abbreviations";
import ValidEmail from "./validEmail/ValidEmail";
import ResetPassword from "./resetPassword/ResetPassword";
import Faq from "./faq/Faq";
import Books from "./books/Books";
import Contact from "./contact/Contact";
import MentorPro from "./MentorPro/MentorPro";
import Vaccin from "./vaccin/Vaccin";
import Legals from "./legals/Legals";
import Confidentiality from "./confidentiality/Confidentiality";

const router = createBrowserRouter([
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/connexion/:type?",
        element: <Connect />,
      },
      {
        path: "/inscription/:step",
        element: <RegistrationSteps />,
      },
      {
        path: "/mon-compte/:tab?",
        element: <Settings />,
      },
      {
        path: "/categorie/:categoryId",
        element: <Categories />,
      },
      {
        path: "/fiche/:sheetId?",
        element: <Sheet />,
      },
      {
        path: "/charte-epopi",
        element: <CustomPage title="Charte ePOPI" pageName="charter" />,
      },
      {
        path: "/le-cmit",
        element: (
          <CustomPage
            title="Le CMIT"
            pageName="cmit"
            description="Le Collège des universitaires des Maladies Infectieuses et Tropicales (CMIT), est la structure nationale regroupant les Enseignants – Chercheurs de Pathologie Infectieuse et Tropicale créé en 1972 (ex APPIT)."
          />
        ),
      },
      {
        path: "/comite-editorial",
        element: <Comity />,
      },
      {
        path: "/membres-du-cmit",
        element: <CmitMembers />,
      },
      {
        path: "/abonnement/facturation",
        element: <Subscription />,
      },
      {
        path: "/abonnement/recapitulatif/:reference",
        element: <SubscriptionRecap />,
        loader: subscriptionLoader,
      },
      {
        path: "/abreviations",
        element: <Abbreviations />,
      },
      {
        path: "/validation-email/:email/:userId",
        element: <ValidEmail />,
      },
      {
        path: "/reinitialiser-mon-mot-de-passe/:email/:token",
        element: <ResetPassword />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/ouvrages",
        element: <Books />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/vaccin/:id",
        element: <Vaccin />,
      },
      {
        path: "/mentorpro/",
        element: <MentorPro />,
      },
      {
        path: "/mentions-legales",
        element: <Legals />,
      },
      {
        path: "/politique-de-confidentialite",
        element: <Confidentiality />,
      },
    ],
  },
]);

export default router;
