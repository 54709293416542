import { FormEvent, useEffect } from "react";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "@tanstack/react-form";
import { z } from "zod";
import { zodValidator } from "@tanstack/zod-form-adapter";

import api from "../../utils/api/axios";
import { notifyApiSuccess } from "../../utils/api/notifyApi";
import { contactObjectLabels } from "../../utils/contact";
import { userSpecialitiesLabels } from "../../utils/user";
import { UserSpeciality } from "../../types/user";

import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";

import "./Contact.css";

const schema = z.object({
  subject: z.string({
    message: "Un objet de message est requis",
  }),
  name: z.string({ message: "Nom requis" }),
  email: z
    .string({ message: "Adresse email requise" })
    .email("Adresse email invalide"),
  speciality: z.nativeEnum(UserSpeciality).or(
    z
      .string()
      .refine((v) => !v)
      .optional()
  ),
  establishment: z.string().optional(),
  message: z
    .string({ message: "Message requis" })
    .max(1000, { message: "Message trop long" }),
});

type FormData = z.infer<typeof schema>;

const Contact = () => {
  const navigate = useNavigate();

  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        await api.post("/contact", value);
        notifyApiSuccess(
          "Votre message a été envoyé, vous recevrez une réponse prochainement"
        );
        navigate("/");
      } catch (e) {
        console.error(e);
      }
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page">
      <CustomBreadcrumbs links={[{ text: "Nous contacter" }]} />
      <div className="page-header">
        <h1 className="page-title">Nous contacter</h1>
      </div>

      <form className="form contact-form" onSubmit={(e) => submit(e)}>
        <Field
          name="subject"
          validators={{ onChange: schema.shape.subject }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Objet du message*</p>
              <Select
                id="select-subject"
                size="small"
                required
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => {
                  const contactObject = contactObjectLabels.find(
                    (obj) => obj.value === e.target.value
                  );
                  field.handleChange(contactObject?.label ?? "");
                }}
              >
                {contactObjectLabels.map((title) => (
                  <MenuItem key={title.value} value={title.value}>
                    {title.label}
                  </MenuItem>
                ))}
              </Select>
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />

        <div className="dual-input-container">
          <Field
            name="name"
            validators={{ onChange: schema.shape.name }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Nom*</p>
                <TextField
                  required
                  size="small"
                  id="name"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="email"
            validators={{ onChange: schema.shape.email }}
            children={(field) => (
              <div className="input-container">
                <p className="input-label">Email*</p>
                <TextField
                  required
                  size="small"
                  id="email"
                  type="email"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
        </div>

        <Field
          name="speciality"
          validators={{ onChange: schema.shape.speciality }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Spécialité</p>
              <Select
                id="select-service"
                size="small"
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) =>
                  field.handleChange(e.target.value as UserSpeciality)
                }
              >
                <MenuItem value="">
                  <em>Aucune</em>
                </MenuItem>
                {userSpecialitiesLabels.map((speciality) => (
                  <MenuItem key={speciality.value} value={speciality.value}>
                    {speciality.label}
                  </MenuItem>
                ))}
              </Select>
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />

        <Field
          name="establishment"
          validators={{ onChange: schema.shape.establishment }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Établissement / Société</p>
              <TextField
                size="small"
                id="establishment"
                type="text"
                fullWidth
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />

        <Field
          name="message"
          validators={{ onChange: schema.shape.message }}
          children={(field) => (
            <div className="input-container">
              <p className="input-label">Message*</p>
              <TextField
                required
                size="small"
                id="message"
                type="text"
                placeholder="Écrivez votre message ici"
                fullWidth
                multiline
                minRows={4}
                value={field.state.value}
                error={!!field.state.meta.errors.length}
                onBlur={field.handleBlur}
                onChange={(e) => field.handleChange(e.target.value)}
              />
              {field.state.meta.errors.length ? (
                <em className="input-error">{field.state.meta.errors}</em>
              ) : null}
            </div>
          )}
        />

        <Subscribe
          // @ts-ignore ignore build error
          selector={(state) => [state.canSubmit, state.isSubmitting]}
          // @ts-ignore ignore build error
          children={([canSubmit, isSubmitting]) => (
            <Button
              type="submit"
              variant="contained"
              disabled={Boolean(!canSubmit) || Boolean(isSubmitting)}
            >
              Envoyer
            </Button>
          )}
        />
      </form>
    </div>
  );
};

export default Contact;
