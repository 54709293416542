import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import Header from "../../components/main/Header";
import Footer from "../../components/main/Footer";
import ArrowLeftTailed from "../../assets/icons/arrows/left-tailed";

import "./Root.css";

const Root = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHome = pathname === "/";

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box id="main">
      <Header />
      <div id="content" className={!isHome ? "content" : ""}>
        {!isHome && isSmallScreen && (
          <div className="go-back-arrow" onClick={() => navigate(-1)}>
            <ArrowLeftTailed color="black" />
          </div>
        )}
        <Outlet />
      </div>
      <Footer />
    </Box>
  );
};

export default Root;
