import { FormEvent, useState } from "react";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useNavigate, useParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { z } from "zod";

import api from "../../utils/api/axios";
import { notifyApiSuccess } from "../../utils/api/notifyApi";

import Logo from "../../assets/logos/logo.svg";

import "./ResetPassword.css";

const schema = z.object({
  password: z
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
  passwordConfirmation: z
    .string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
});

type FormData = z.infer<typeof schema>;

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { email, token } = params;

  const [mdp, setMdp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      if (value.password !== value.passwordConfirmation) {
        return { errors: "Les mots de passe ne correspondent pas" };
      }

      try {
        await api.put("/auth/reset-password", {
          email,
          token,
          password: value.password,
        });
        notifyApiSuccess("Mot de passe modifié avec succès");
        navigate("/connexion/se-connecter");
      } catch (_) {
        return { errors: "Email ou mot de passe incorrect" };
      }
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <div className="connect-main">
      <div className="connect-content">
        <form className="form" onSubmit={(e) => submit(e)}>
          <img className="reset-password__logo" src={Logo} alt="logo" />

          <p>Réinitialiser mon mot de passe</p>

          <div className="reset-password__input-container">
            <Field
              name="password"
              defaultValue=""
              validators={{ onChange: schema.shape.password }}
              children={(field) => (
                <>
                  <p className="reset-password__input-label">Mot de passe</p>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    placeholder="••••••••"
                    autoComplete="new-password"
                    fullWidth
                    value={field.state.value}
                    error={!!field.state.meta.errors.length}
                    onBlur={field.handleBlur}
                    onChange={(e) => {
                      field.handleChange(e.target.value);
                      setMdp(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {field.state.meta.errors.length ? (
                    <em className="reset-password__input-error">
                      {field.state.meta.errors}
                    </em>
                  ) : null}
                </>
              )}
            />
          </div>

          <div className="reset-password__input-container">
            <Field
              name="passwordConfirmation"
              validators={{
                onChange: schema.shape.passwordConfirmation.and(
                  z.string().refine((v) => v === mdp, {
                    message: "Les mots de passe ne correspondent pas",
                  })
                ),
              }}
              children={(field) => (
                <>
                  <p className="reset-password__input-label">
                    Confirmation du mot de passe
                  </p>
                  <TextField
                    type={showPasswordConfirmation ? "text" : "password"}
                    placeholder="••••••••"
                    autoComplete="new-password"
                    fullWidth
                    value={field.state.value}
                    error={!!field.state.meta.errors.length}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPasswordConfirmation(
                                !showPasswordConfirmation
                              )
                            }
                            edge="end"
                          >
                            {showPasswordConfirmation ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {field.state.meta.errors.length ? (
                    <em className="reset-password__input-error">
                      {field.state.meta.errors}
                    </em>
                  ) : null}
                </>
              )}
            />
          </div>

          <Subscribe
            // @ts-ignore ignore build error
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            // @ts-ignore ignore build error
            children={([canSubmit]) => (
              <Button variant="contained" disabled={!canSubmit} type="submit">
                Changer mon mot de passe
              </Button>
            )}
          />

          <div className="reset-password__hide-part" />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
