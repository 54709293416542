import { get, keys } from "idb-keyval";
import { GetSheet } from "../types/api/sheet";
import { GetCategory } from "../types/api/category";

export const sheetRedirection = async (
  legacySheetId: string,
  legacyCategoryId: string
) => {
  const keys_ = await keys();

  const sheetKeys = keys_.filter((key) => key.toString().startsWith("sheet-"));

  const sheets = await Promise.all(
    sheetKeys.map(async (key) => {
      const sheet = await get<GetSheet>(key);
      return sheet;
    })
  );

  const sheet = sheets.find((sheet) => {
    if (!sheet || sheet.legacyCategoryId !== Number.parseInt(legacyCategoryId))
      return false;
    const parsedLegacySheetId = Number.parseInt(legacySheetId);
    const reduceLegacyIdByThousand = Math.trunc(sheet.legacyId / 10000);
    const reduceLegacyIdByTen = Math.trunc(sheet.legacyId / 10);
    return (
      reduceLegacyIdByThousand === parsedLegacySheetId ||
      reduceLegacyIdByTen === parsedLegacySheetId
    );
  });

  return sheet?.sheet.id;
};

export const categoryRedirection = async (legacyCategoryId: string) => {
  const keys_ = await keys();
  const categoryKeys = keys_.filter((key) =>
    key.toString().startsWith("category-")
  );
  const categories = await Promise.all(
    categoryKeys.map(async (key) => {
      const category = await get<GetCategory>(key);
      return category;
    })
  );
  const category = categories.find(
    (category) =>
      !category || category.legacyId !== Number.parseInt(legacyCategoryId)
  );
  return category?.category.id;
};
