import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import router from "./routes/router";

import api, { removeToken, setToken } from "./utils/api/axios";
import { notifyApiSuccess, notifyApiWarning } from "./utils/api/notifyApi";
import { UserLogin, UserRole } from "./types/api/user";
import { GetAllData, GetAllDataVaccins } from "./types/api/allDatas";
import { set, clear } from "idb-keyval";
import {
  setFreeCategories,
  setFreeSheets,
  setFreeVaccins,
} from "./store/reducers/freeContent.slice";

import { useAppDispatch, useAppSelector } from "./store/hook";
import {
  setAuthUser,
  setHasNoNetwork,
  setNotifiedHasNetwork,
  setNotifiedHasNoNetwork,
  setNotifiedOfflineOn,
} from "./store/reducers/auth.slice";
import { GetCategory } from "./types/api/category";
import { GetSheet } from "./types/api/sheet";

function App() {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const {
    isAuth,
    hasNoNetwork,
    user,
    notifiedHasNetwork,
    notifiedHasNoNetwork,
    notifiedOfflineOn,
  } = auth;

  const setSheets = async (sheets: GetSheet[]) => {
    await Promise.all(
      sheets.map(async (sheet) => {
        if (sheet.sheet) await set("sheet-" + sheet.sheet.id, sheet);
      })
    );
  };

  const setCategories = async (categories: GetCategory[]) => {
    await Promise.all(
      categories.map(async (category) => {
        if (category) await set("category-" + category.category.id, category);
      })
    );
  };

  const setVaccins = async (vaccins: GetAllDataVaccins[]) => {
    await Promise.all(
      vaccins.map(async (vaccin) => {
        if (vaccin) await set("vaccin-" + vaccin.id, vaccin);
      })
    );
  };

  // init to false to avoid succes message on first load
  const fetchAllDatas = async () => {
    try {
      const { data } = await api.get<GetAllData | boolean>("/all-data");
      // is case subscription is not active
      if (!data || typeof data === "boolean") {
        if (data === false) {
          clear();
        }
        return;
      }
      clear();
      const {
        categories,
        sheets,
        vaccins,
        freeCategories,
        freeSheets,
        freeVaccins,
      } = data;
      if (freeCategories) dispatch(setFreeCategories(freeCategories));
      if (freeSheets) dispatch(setFreeSheets(freeSheets));
      if (freeVaccins) dispatch(setFreeVaccins(freeVaccins));

      await setSheets(sheets);
      await setCategories(categories);
      await setVaccins(vaccins);
      if (!notifiedOfflineOn) {
        notifyApiSuccess("Votre mode hors ligne est opérationnel");
        dispatch(setNotifiedOfflineOn(true));
      }
    } catch (_) {}
  };

  const checkUserEstablishmentConnexion = async () => {
    try {
      const { data: ipData } = await api.post<UserLogin | boolean | undefined>(
        "/auth/ip/login"
      );
      if (ipData && typeof ipData !== "boolean") {
        //notifyApiSuccess("Vous êtes connecté via votre IP");
        dispatch(setAuthUser(ipData));
        setToken(ipData.token.token);
        return;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const token =
          urlParams.get("token") || localStorage.getItem("establishmentToken");
        const { data: tokenData } = await api.post<
          UserLogin | boolean | undefined
        >("/auth/token/login/", null, {
          params: {
            token,
          },
        });
        if (tokenData && typeof tokenData !== "boolean") {
          //  notifyApiSuccess("Vous êtes connecté via votre token");
          dispatch(setAuthUser(tokenData));
          setToken(tokenData.token.token);
          localStorage.setItem("establishmentToken", token || "");
          return;
        } else if (
          user?.role === UserRole.ESTABLISHMENT &&
          ipData !== true &&
          tokenData !== true
        ) {
          try {
            await api.post("/auth/logout");
          } catch (_) {
            console.error("Logout failed");
          }
          removeToken();
        }
      }
    } catch (_) {}
  };

  useEffect(() => {
    if (!hasNoNetwork) {
      checkUserEstablishmentConnexion();
      fetchAllDatas();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoNetwork, isAuth]);

  useEffect(() => {
    if (hasNoNetwork) {
      if (!notifiedHasNoNetwork) {
        dispatch(setNotifiedHasNoNetwork(true));
        notifyApiWarning("Vous n'avez pas de connexion internet");
      }
      if (notifiedHasNetwork) {
        dispatch(setNotifiedHasNetwork(false));
      }
      const intervalId = setInterval(async () => {
        try {
          await api.get("/network-test");
          if (!notifiedHasNetwork) {
            dispatch(setNotifiedHasNetwork(true));
            notifyApiSuccess("Vous êtes connecté à internet");
          }
          dispatch(setNotifiedHasNoNetwork(false));
        } catch (_) {
          dispatch(setHasNoNetwork(true));
        }
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [hasNoNetwork, dispatch, notifiedHasNetwork, notifiedHasNoNetwork]);

  return <RouterProvider router={router} />;
}

export default App;
