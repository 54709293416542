import { Divider, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";

import api, { removeToken } from "../../utils/api/axios";

interface ProfileMenuProps {
  isAuth: boolean;
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: (closeTab?: boolean) => void;
  isEstablishment?: boolean;
}

const ProfileMenu = ({
  isAuth,
  anchorEl,
  open,
  handleClose,
  isEstablishment,
}: ProfileMenuProps) => {
  const navigate = useNavigate();

  const handleDisconnect = async () => {
    handleClose();
    isEstablishment && localStorage.removeItem("establishmentToken");
    try {
      await api.post("/auth/logout");
    } catch (_) {
      console.error("Logout failed");
    }
    removeToken();
    navigate("/connexion", { replace: true });
  };

  const navigateTo = (path: string) => {
    handleClose(true);
    navigate(path);
  };

  return (
    <Menu
      id="account-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={() => handleClose()}
      elevation={0}
      PaperProps={{
        sx: {
          overflow: "visible",
          borderRadius: "8px",
          marginTop: "8px",
          minWidth: 180,
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          "& .MuiMenuItem-root": {
            minHeight: "34px",
            fontSize: "16px",
            padding: "4px 16px",
          },
        },
      }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    >
      {isEstablishment
        ? [
            <MenuItem key="item-disconnect" onClick={handleDisconnect}>
              Se déconnecter
            </MenuItem>,
          ]
        : isAuth
        ? [
            <MenuItem
              key="item-my-account"
              onClick={() => navigateTo("/mon-compte")}
            >
              Mon compte
            </MenuItem>,
            <Divider key="item-divider" variant="middle" />,
            <MenuItem key="item-disconnect" onClick={handleDisconnect}>
              Se deconnecter
            </MenuItem>,
          ]
        : [
            <MenuItem
              key="item-subscription"
              onClick={() => navigateTo("/connexion/inscription")}
            >
              S'abonner
            </MenuItem>,
            <MenuItem
              key="item-login"
              onClick={() => navigateTo("/connexion/se-connecter")}
            >
              Se connecter
            </MenuItem>,
          ]}
    </Menu>
  );
};

export default ProfileMenu;
