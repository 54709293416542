import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface FreeContentState {
  freeSheets: number[];
  freeCategories: number[];
  freeVaccins: number[];
}

const initialState: FreeContentState = {
  freeSheets: [],
  freeCategories: [],
  freeVaccins: [],
};

export const freeContentSlice = createSlice({
  name: "recentSheets",
  initialState,
  reducers: {
    setFreeSheets: (state, action: PayloadAction<number[]>) => {
      state.freeSheets = action.payload;
    },
    setFreeCategories: (state, action: PayloadAction<number[]>) => {
      state.freeCategories = action.payload;
    },
    setFreeVaccins: (state, action: PayloadAction<number[]>) => {
      state.freeVaccins = action.payload;
    },
  },
});

export const { setFreeSheets, setFreeCategories, setFreeVaccins } =
  freeContentSlice.actions;

export default freeContentSlice.reducer;
