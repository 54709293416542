import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowRightTailed from "../../assets/icons/arrows/right-tailed";
import cmit from "../../assets/images/partners/cmit-large.png";
import spilf from "../../assets/images/partners/spilf.png";
import mesVaccins from "../../assets/images/partners/mes-vaccins-net.png";

import SearchBar from "./SearchBar";
import { useEffect, useRef } from "react";

interface HeroProps {
  isAuth?: boolean;
}

const Hero = ({ isAuth }: HeroProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSearch = location.search === "?type=search";

  const inpuRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearch && inpuRef.current) {
      inpuRef.current?.focus();
    }
  }, [isSearch]);

  return (
    <div className="hero">
      <img className="hero__cmit-logo" src={cmit} alt="CMIT logo" />

      <div className="hero__title--container">
        <h1 className="hero__title">L'expertise en infectiologie</h1>
        <p className="hero__description">
          Guide pratique d'aide à la prescription des anti-infectieux
        </p>
      </div>

      <div className="hero-footer">
        {isAuth ? (
          <SearchBar ref={inpuRef} />
        ) : (
          <div className="hero-not-auth">
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate("/connexion/s-inscrire")}
              sx={{
                width: "min-content",
                padding: "16px 24px",
                borderRadius: "8px",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              S'abonner
            </Button>
            <Button
              sx={{ color: "white", fontSize: 16, fontWeight: 600 }}
              size="large"
              onClick={() => {
                const discoverDiv = document.getElementById("decouverte");
                if (discoverDiv) {
                  const top = discoverDiv.offsetTop;
                  const topOffset = top - 30;
                  window.scrollTo({ top: topOffset, behavior: "smooth" });
                }
              }}
            >
              Découvrir ePOPI
              <ArrowRightTailed color="white" />
            </Button>
          </div>
        )}

        <div className="hero-partners">
          <p className="hero-partners--title">En partenariat avec :</p>
          <a
            href="https://www.infectiologie.com/fr/spilf-presentation.html"
            target="_blank"
            rel="noreferrer"
          >
            <img className="hero-partners--img" src={spilf} alt="SPILF logo" />
          </a>
          <a href="https://www.mesvaccins.net" target="_blank" rel="noreferrer">
            <img
              className="hero-partners--img"
              src={mesVaccins}
              alt="mes-vaccins.net logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
