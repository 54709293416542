import { forwardRef, useEffect, useState } from "react";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../store/hook";
import { SheetSearch } from "../../types/api/search";

import SearchIcon from "../../assets/icons/search";
import { get, keys } from "idb-keyval";
import { GetAllDataVaccins } from "../../types/api/allDatas";
import { GetSheet } from "../../types/api/sheet";
import { GetCategory } from "../../types/api/category";

interface SearchBarProps {
  bordered?: boolean;
  onSearchNavigation?: () => void;
}
const removeAccents = (name: string) =>
  name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const searchInsensitiveToAccentsAndCase = (search: string, name: string) => {
  const normalizedText = removeAccents(name).toLowerCase();
  const normalizedSearchTerm = removeAccents(search).toLowerCase();
  return normalizedText.includes(normalizedSearchTerm);
};

const getOption = async (
  key: IDBValidKey,
  typeString: string
): Promise<SheetSearch> => {
  switch (typeString) {
    case "sheet":
      const valueSheet = await get<GetSheet>(key);
      if (!valueSheet) return { id: -1, name: "unknown", type: "sheet" };
      return {
        id: valueSheet.sheet.id,
        name: valueSheet.sheet.name,
        type: "sheet",
      };
    case "category":
      const valueCategory = await get<GetCategory>(key);
      if (!valueCategory) return { id: -1, name: "unknown", type: "category" };

      return {
        id: valueCategory.category.id,
        name: valueCategory.category.name,
        type: "category",
      };
    default:
      const valueVaccin = await get<GetAllDataVaccins>(key);
      if (!valueVaccin) return { id: -1, name: "unknown", type: "sheet" };
      return { id: valueVaccin.id, name: valueVaccin.name, type: "vaccin" };
  }
};

const SearchBar = forwardRef<HTMLInputElement, any>(
  ({ bordered, onSearchNavigation }: SearchBarProps, ref) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isAuth = useAppSelector((state) => state.auth);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<SheetSearch[]>([]);
    // useEffect(() => {
    //   if (isAuth) fetch(inputValue);
    // }, [inputValue, isAuth]);

    const getOptions = async () => {
      const keys_ = await keys();
      const newSheetOptions: SheetSearch[] = await Promise.all(
        keys_.map(async (key) => {
          const typeString = key.toString().split("-")[0];
          const option = await getOption(key, typeString);
          return option;
        })
      );
      const filteredOptions = newSheetOptions.filter(
        (option) => option.id !== -1
      );
      setOptions(filteredOptions);
    };

    useEffect(() => {
      getOptions();
    }, [isAuth]);

    return (
      <div className="search-bar">
        <Autocomplete
          sx={{
            "& .MuiInputBase-root": {
              border: bordered ? "1px solid var(--light-grey)" : "none",
              borderRadius: "8px",
            },
            "& .MuiAutocomplete-popupIndicator": {
              display: "none",
            },
            "&.MuiAutocomplete-hasPopupIcon": {
              "& .MuiOutlinedInput-root": {
                padding: { xs: "4px 8px", md: "16px" },
              },
              "& .MuiInputBase-input": {
                padding: 0,
              },
            },
          }}
          fullWidth
          getOptionLabel={(option) =>
            typeof option === "string" ? option : option.name
          }
          filterOptions={(options) => {
            if (inputValue === "") return [];
            return options.filter((option) =>
              searchInsensitiveToAccentsAndCase(inputValue, option.name)
            );
          }}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          openOnFocus
          noOptionsText="Pas de résultats"
          value={options.find((option) => option.name === inputValue)}
          onBlur={() => setInputValue("")}
          inputValue={inputValue}
          onChange={(_, newValue) => {
            if (!newValue) return;
            switch (newValue.type) {
              case "vaccin":
                navigate(`/vaccin/${newValue.id}`);
                onSearchNavigation && onSearchNavigation();
                break;
              case "sheet":
                navigate(`/fiche/${newValue.id}`);
                onSearchNavigation && onSearchNavigation();
                break;
              case "category":
                navigate(`/categorie/${newValue.id}`);
                onSearchNavigation && onSearchNavigation();
            }
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              sx={{
                "&:hover": {
                  border: "none",
                },
                "&:focus": {
                  border: "none",
                },
                "& .MuiOutlinedInput-root": {
                  padding: 0,
                  backgroundColor: "white",
                  border: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  color: "var(--black)",
                  fontSize: { xs: "14px", md: "16px" },
                  border: "none",
                },
              }}
              placeholder="Rechercher une infection, un vaccin..."
              inputRef={ref}
              {...params}
              fullWidth
              InputProps={{
                ...params?.InputProps,
                startAdornment: !isSmallScreen ? (
                  <InputAdornment position="start" sx={{ height: "24px" }}>
                    <SearchIcon color="black" />
                  </InputAdornment>
                ) : undefined,
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={`${option.type}-${option.name}-${option.id}`}>
              <Link
                className="search-bar__option"
                to={`/${option.type === "sheet" ? "fiche" : "categorie"}/${
                  option.id
                }`}
                onClick={onSearchNavigation}
              >
                <p className="search-bar__option--name">{option.name}</p>
              </Link>
            </li>
          )}
        />
      </div>
    );
  }
);

export default SearchBar;
