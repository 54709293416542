import { useEffect } from "react";

import api from "../../utils/api/axios";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setAuthUser } from "../../store/reducers/auth.slice";
import { UserLogin } from "../../types/api/user";

import Hero from "../../components/home/Hero";
import DiscoverEpopi from "../../components/home/DiscoverEpopi";
import HomeCategories from "../../components/home/HomeCategories";
import RecentSheets from "../../components/home/RecentSheets";
import EpopiOnSmartphone from "../../components/home/EpopiOnSmartphone";

import "./Home.css";

const Home = () => {
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.auth);
  const { isAuth, hasNoNetwork } = auth;

  const getMe = async () => {
    try {
      const { data } = await api.get<UserLogin>("/auth/me");
      if (data) {
        dispatch(setAuthUser(data));
      }
    } catch (_) {}
  };

  useEffect(() => {
    if (isAuth && !hasNoNetwork) getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return (
    <>
      <Hero isAuth={isAuth} />
      {!isAuth && <DiscoverEpopi />}
      <HomeCategories isAuth={isAuth} />
      {isAuth && <RecentSheets />}
      {isAuth && <EpopiOnSmartphone />}
    </>
  );
};

export default Home;
