import React, { useEffect, useState } from "react";

import api from "../../utils/api/axios";
import { Book } from "../../types/api/books";

import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";

import "./Books.css";
import { Button, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import Plus from "../../assets/icons/plus";

interface BookItem extends Book {
  isOpen?: boolean;
}
interface BookLinkProps {
  book: Book;
}

const BookLink = ({ book }: BookLinkProps) => {
  return (
    <Link className="book-item__link" to={book.orderLink} target="_blank">
      <Button variant="contained" sx={{ fontWeight: 400 }} size="large">
        {book.price === 0
          ? "Télécharger le e-book"
          : " Commander directement chez l'éditeur Alinéa Plus"}
      </Button>
    </Link>
  );
};

const Books = () => {
  const isSmallScreen = useMediaQuery("(max-width: 1000px)");

  const [books, setBooks] = useState<BookItem[]>();

  const fetchData = async () => {
    try {
      const { data } = await api.get<Book[]>(`/books/pwa`);
      setBooks(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page">
      <CustomBreadcrumbs links={[{ text: "Ouvrages" }]} />
      <div className="page-header">
        <h1 className="page-title">Ouvrages du CMIT</h1>
      </div>

      <div className="book-content">
        {books?.map((book) => (
          <div key={book.id} className="book-item">
            <div className="book-item__header">
              <div className="book-item__image">
                <img src={book.imageUrl} alt="book" />
              </div>
              <div className="book-item__header--content">
                <div className="book-item__header--content-header">
                  <div className="book-item__header--content-header-infos">
                    <p className="book-item__title">{book.name}</p>
                    <p className="book-item__price">
                      {book.price === 0
                        ? "Gratuit"
                        : `${book.price.toLocaleString("fr-FR", {
                            style: "currency",
                            currency: "EUR",
                          })} TTC`}
                    </p>
                  </div>

                  {!isSmallScreen && <BookLink book={book} />}
                </div>
                {!isSmallScreen && (
                  <p className="book-item__description">
                    {book.description.split("\n").map((paragraph) => (
                      <>
                        {paragraph}
                        <br></br>
                      </>
                    ))}
                  </p>
                )}
              </div>
            </div>
            {isSmallScreen && (
              <div className="book-item__content">
                <p
                  className={[
                    "book-item__description",
                    !book.isOpen ? "close" : "",
                  ].join(" ")}
                >
                  {book.description.split("\n").map((paragraph) => (
                    <>
                      {paragraph}
                      <br></br>
                    </>
                  ))}
                </p>
                {!book.isOpen && (
                  <Button
                    variant="text"
                    sx={{ alignSelf: "center" }}
                    onClick={() =>
                      setBooks(
                        books.map((b) => ({ ...b, isOpen: b.id === book.id }))
                      )
                    }
                  >
                    <Plus color="navy" /> Voir plus
                  </Button>
                )}
              </div>
            )}
            {isSmallScreen && <BookLink book={book} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Books;
