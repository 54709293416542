import { useEffect } from "react";

const Legals = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <h1>MENTIONS LÉGALES &amp; CONDITIONS GÉNÉRALES D’UTILISATION</h1>
      <p>
        <br />
        &nbsp;
      </p>
      <p>
        Veuillez lire attentivement les présentes Mentions Légales et conditions
        d’utilisation du site epopi.fr
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>1- Éditeur</strong>
      </p>
      <p>
        ALINÉA Plus
        <br />
        8, rue Froidevaux 75014 PARIS
        <br />
        Tél&nbsp;: 01 46 67 62 53 – Fax&nbsp;: 01 41 38 12 02
        <br />
        contact@ePOPI.fr
        <br />
        SAS au capital de 10 000 €<br />
        RCS Paris 753 930 445 – TVA Intracommunautaire FR55 753 930 445
        <br />
        &nbsp;
      </p>
      <p>
        Directeur de la publication du site www.epopi.fr
        <br />
        Nadège LEFEBVRE :&nbsp;nadege.lefebvre@alineaplus.fr
      </p>
      <p>Abonnements&nbsp;:&nbsp;contact@ePOPI.fr&nbsp;</p>
      <p>
        www.epopi.fr est hébergé, sur un serveur dédié Alinéa Plus et protégé,
        par la société OVHcloud pour la conservation et le traitement des
        données soumises. OVHcloud est immatriculée au RCS de Lille Métropole
        sous le numéro 424761419 et domiciliée au 2 rue Kellermann à Roubaix.
        Elle est joignable au téléphone au +33972 10 10 07.
      </p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>2- Rédaction</strong>
      </p>
      <p>
        Collège des universitaires de Maladies Infectieuses et Tropicales (CMIT)
        <br />
        Siège social : CMIT - Maison de l'Infectiologie 21 rue Beaurepaire 75010
        PARIS
        <br />
        Président du CMIT : Pr David Boutoille - Service des Maladies
        Infectieuses et Tropicales - CHRU&nbsp;Hôtel Dieu - 44093 NANTES Cedex 1
        <br />
        &nbsp;
      </p>
      <p>
        <strong>Responsable des Ouvrages Pédagogiques du CMIT</strong>&nbsp;:
      </p>
      <ul>
        <li>Pr Charles Cazanave</li>
      </ul>
      <p>Hôpital Pellegrin - Service Maladies Infectieuses et Tropicales</p>
      <p>Place Amélie Raba-Léon - 33076 Bordeaux Cedex</p>
      <p>
        <br />
        <strong>
          Coordonnateurs du Comité Scientifique et de veille ePOPI
        </strong>
        &nbsp;:
      </p>
      <ul>
        <li>
          Pr Christian Chidiac
          <br />
          Service des Maladies Infectieuses et Tropicales - Hôpital de la Croix
          Rousse
          <br />
          103 grande rue de la Croix Rousse - 69317 Lyon Cedex 04
        </li>
        <li>
          Pr Bruno HOEN
          <br />
          Service des Maladies Infectieuses et Tropicales - CHU de Besançon –
          Université de Lorraine - 3, boulevard Fleming - 25030 Besançon Cedex
        </li>
        <li>
          Pr Odile Launay
          <br />
          CIC de Vaccinologie Cochin-Pasteur - Pôle de Médecine - Hôpital Cochin
          <br />
          27 rue du Faubourg Saint-Jacques - 75679 Paris Cedex 14
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>
        <strong>3- Charte ePOPI</strong>
      </p>
      <p>
        Le site www.epopi.fr est régis par une charte disponible sur le site
        (Charte ePOPI) ayant trait aux points suivants&nbsp;:
      </p>
      <ul>
        <li>le Comité éditorial et le Comité scientifique,</li>
        <li>l’objectif du ePOPI,</li>
        <li>le public visé,</li>
        <li>l’élaboration collégiale,</li>
        <li>la méthodologie d’élaboration,</li>
        <li>l’indépendance et la responsabilité,</li>
        <li>le financement,</li>
        <li>
          la confidentialité et la protection des données personnelles conformes
          au RGPD
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        <strong>4- Droits protégés / Copyright</strong>
      </p>
      <p>
        L’intégralité du CONTENU (textes, illustrations, photographies, vidéos,
        architecture, arborescence, charte graphique, codes et logiciels
        informatiques), et généralement tous les éléments constituant le présent
        site ePOPI, développés par ALINÉA Plus et le CMIT sont protégés par la
        Législation applicable en matière de Droit d’auteur, de Droit des
        marques, de Droit des dessins et modèles et de concurrence déloyale.
      </p>
      <p>
        En souscrivant à un abonnement individuel annuel ePOPI, l’abonné
        s’inscrit comme utilisateur et obtient une licence temporaire et
        personnelle d’utilisation du site www.epopi.fr et un droit d’impression
        ou de copie à usage privé, en indiquant la source et les mentions de
        droit d’auteur et autres mentions de droit de propriété. Chaque licence
        et droit d’impression ou de copie à usage privé sont attachés à un
        identifiant et un mot de passe.
      </p>
      <p>
        L’utilisation collective d’une licence et/ou du droit de copie est
        interdite sauf dans le cadre d'un abonnement lié à un établissement ou à
        un groupe d'établissements. L'établissement ou le groupe
        d'établissements obtient auprès d'Alinéa Plus une licence temporaire
        d’utilisation du site www.epopi.fr uniquement pour ses terminaux
        informatiques internes et pour les personnels affiliés, via une ou
        plusieurs adresses IP communiquées à l'éditeur, et selon une grille
        tarifaire tenant compte de la taille de l'établissement ou du groupe
        d'établissements déterminée par le nombre de praticiens ou de lits,
        figurant explicitement sur les devis et factures d'abonnements établis
        par ALINÉA Plus . En cas d'abonnement d'un établissement lié à un réseau
        informatique d'établissements possédant la ou les mêmes adresses IP et
        si ces autres établissements ne souscrivent pas d'abonnement ePOPI, la
        connexion et le droit d'accès peuvent s'effectuer à l'aide d'une clé
        d'identification unique et spécifique à l'établissement, confidentielle,
        non transférable et fournie par Alinéa Plus. Toute utilisation du ePOPI
        au-delà des droits d'usage consentis, soit à travers un réseau
        informatique local, régional, national ou international, soit par
        communication de la clé d'identification confidentielle et spécifique à
        l'établissement, est illicite et frauduleuse. Chaque établissement ou
        groupe d'établissements doit s'assurer de la non-utilisation possible de
        ses droits d'usage ePOPI par un autre établissement ou un autre tiers et
        est seul responsable de l'utilisation frauduleuse via son réseau
        informatique qui est de son ressort exclusif. En cas d'utilisation
        illicite ou frauduleuse avérée et constatée par Alinéa Plus ou le CMIT,
        Alinéa Plus en avertit l'établissement et la connexion ePOPI est
        immédiatement interrompue sans aucun dédommagement. Elle ne pourra
        reprendre dans le cadre de l'abonnement consenti qu'après résolution du
        problème par l'établissement abonné, seul responsable de son réseau
        informatique et de l'utilisation de ses droits d'usage ePOPI.
      </p>
      <p>
        Toute représentation, traduction, diffusion et/ou exploitation ou
        reproduction même partielle du CONTENU du présent site ePOPI, quel que
        soit le procédé, faite sans l’autorisation écrite et préalable de
        l’éditeur et du CMIT, est illicite et constitue une contrefaçon.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>5- Non cessibilité</strong>
      </p>
      <p>
        L’abonné inscrit à www.epopi.fr ne peut céder tout ou partie de ses
        droits et obligations à un tiers. Il s’interdit de sous louer, et/ou de
        mettre à disposition d’une manière quelconque, tout ou partie des droits
        et services auxquels il a accès via www.epopi.fr.
      </p>
      <p>
        L’abonnement individuel et l’inscription sont nominatifs et personnels,
        une seule inscription est possible par adresse électronique, sauf
        exception préalablement approuvée par l’éditeur et le CMIT.
      </p>
      <p>
        L'abonnement collectif (établissement ou groupe d'établissements) est
        nominatif, au nom de l'établissement ou du groupe d'établissements qui
        apparait en page d'accueil www.epopi.fr, une fois établi par l'éditeur
        le droit de connexion et d'accès au contenu sur une ou plusieurs
        adresses IP que lui aura communiqué l'établissement ou le groupe
        d'établissements, ou à l'aide d'une clé d'identification spécifique
        fournie par l'éditeur. L'établissement ou le groupe d'établissements
        abonné ne peut sous-louer, mettre à disposition, transférer ou céder son
        abonnement à un autre établissement ou à tout autre tiers, sauf
        exception préalablement approuvée par l’éditeur et le CMIT.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>6- Conditions générales de vente et d’abonnement</strong>
      </p>
      <p>Vendeur&nbsp;:</p>
      <p>
        ALINÉA Plus
        <br />
        8, rue Froidevaux 75014 PARIS
        <br />
        Tél&nbsp;: 01 46 67 62 53 – Fax&nbsp;: 01 41 38 12 02
        <br />
        contact@ePOPI.fr
        <br />
        SAS au capital de 10 000 €<br />
        RCS Paris 753 930 445 – TVA Intracommunautaire FR55 753 930 445
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ABONNEMENT INDIVIDUEL</strong>
      </p>
      <p>Le prix affiché de l’abonnement TTC est valable pour un an.</p>
      <p>
        L’abonnement est nominatif, attaché à un identifiant et à un mot de
        passe personnel, dont le maintien du secret est de votre ressort
        exclusif.
      </p>
      <p>
        L’accès à l’ensemble du CONTENU du site www.epopi.fr est disponible
        après avoir complété et validé le formulaire d’abonnement, accepter les
        présentes mentions légales et effectuer le paiement de l’abonnement qui
        s’effectue uniquement en ligne par carte bancaire à l’aide d’une
        solution de paiement en ligne Axepta (solution sécurisée avec cryptage
        des données SSL et respect des exigences de protection DSP2, PCI DSS),
        sous la seule réserve de la validation du paiement en ligne et de
        l’envoi par ALINÉA Plus de la confirmation d’abonnement, de vos
        identifiant et mot de passe personnel et de la facture d’abonnement.
      </p>
      <p>
        L’abonnement est annuel de date à date, à compter de la date de
        confirmation d’abonnement et de réception de vos identifiant et mot de
        passe. ALINÉA Plus vous fera parvenir par mail et plusieurs jours avant
        la date de fin de votre abonnement des alertes de fin d’abonnement et de
        fermeture de votre accès au cas où celui-ci ne serait pas reconduit.
      </p>
      <p>
        <br />
        <strong>
          ABONNEMENT COLLECTIF (Établissement ou groupe d'établissements)
        </strong>
      </p>
      <p>
        Les établissements, organismes ou collectivités souhaitant souscrire un
        abonnement pour leurs affiliés bénéficient d'un tarif spécifique et
        dégressif. La grille tarifaire tenant compte de la taille de
        l'établissement ou du groupe d'établissements déterminée par le nombre
        de praticiens ou de lits est disponible sur simple demande à ALINÉA
        Plus.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>NOUS CONTACTER.</strong>
      </p>
      <p>ALINEA Plus</p>
      <p>
        Nadège LEFEBVRE :&nbsp;nadege.lefebvre@alineaplus.fr&nbsp;- 01 41 38 37
        86
      </p>
      <p>
        ou Marie-Laure Ducoudray : ml.ducoudray@alineaplus.fr - 01 46 67 62 53
      </p>
      <p>
        ou Fabienne Feuillade :&nbsp;fabienne.feuillade@alineaplus.fr&nbsp;- 01
        46 67 62 53
        <br />
        8, rue Froidevaux 75014 PARIS
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>7- Modification et disponibilité de www.epopi.fr</strong>
      </p>
      <p>
        L’éditeur fait de son mieux pour maintenir accessible son site en
        permanence mais n’est tenu à aucune obligation d’y parvenir. En cas
        d’interruption indispensable pour modifications de contenu à
        l’initiative du CMIT et de l’éditeur, un message d’interruption
        temporaire pour travaux avec préavis sera indiqué. L’éditeur et le CMIT
        ne sont en aucune façon responsables de toute autre interruption liée
        aux réseaux, aux fournisseurs d’accès ou à un problème technique, quel
        qu’en soit l’origine. &nbsp;
      </p>
      <p>
        <br />
        <i>Dernière mise à jour : 25 janvier 2025</i>
      </p>
    </div>
  );
};

export default Legals;
