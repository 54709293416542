import { FormEvent } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import { Button, TextField } from "@mui/material";

import api from "../../utils/api/axios";
import { notifyApiError, notifyApiSuccess } from "../../utils/api/notifyApi";

import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from "../StyledDialog";

const schema = z.object({
  email: z
    .string({ message: "Adresse email requise" })
    .email("Adresse email invalide"),
});

type FormData = z.infer<typeof schema>;

interface ResetPasswordDialogProps {
  open: boolean;
  onClose: (refresh?: boolean) => void;
}

const ResetPasswordDialog = ({ open, onClose }: ResetPasswordDialogProps) => {
  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        await api.post("/auth/reset-password", {
          ...value,
          origin: "pwa",
        });
        notifyApiSuccess("Email de réinitialisation de mot de passe envoyé");
        onClose(true);
      } catch (e) {
        notifyApiError(
          "Erreur lors de l'envoi de l'email de réinitialisation de mot de passe"
        );
      }
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <StyledDialog open={open} onClose={() => onClose()} fullWidth>
      <form onSubmit={(e) => submit(e)}>
        <StyledDialogTitle
          sx={{
            fontSize: 20,
            fontWeight: 600,
            color: "var(--navy)",
            padding: "24px",
          }}
        >
          Réinitialisation du mot de passe
        </StyledDialogTitle>

        <StyledDialogContent>
          <Field
            name="email"
            validators={{ onChange: schema.shape.email }}
            children={(field) => (
              <div className="input-container">
                <TextField
                  required
                  size="small"
                  id="email"
                  label="Email"
                  type="text"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                  sx={{ marginTop: "5px" }}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
        </StyledDialogContent>

        <StyledDialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            Annuler
          </Button>
          <Subscribe
            // @ts-ignore ignore build error
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            // @ts-ignore ignore build error
            children={([canSubmit, isSubmitting]) => (
              <Button
                type="submit"
                variant="contained"
                disabled={Boolean(!canSubmit) || Boolean(isSubmitting)}
              >
                Réinitialiser
              </Button>
            )}
          />
        </StyledDialogActions>
      </form>
    </StyledDialog>
  );
};

export default ResetPasswordDialog;
