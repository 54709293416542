import React, { useEffect, useState } from "react";

import api from "../../utils/api/axios";
import { Faq } from "../../types/api/faq";

import CustomBreadcrumbs from "../../components/CustomBreadcrumbs";

import "./Faq.css";

const FaqPage = () => {
  const [data, setData] = useState<Faq[]>();

  const fetchData = async () => {
    try {
      const { data } = await api.get<Faq[]>(`/faq`);
      setData(data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page">
      <CustomBreadcrumbs links={[{ text: "FAQ" }]} />
      <div className="page-header">
        <h1 className="page-title">FAQ</h1>
      </div>

      <div className="faq-content">
        {data?.map((faq) => (
          <React.Fragment key={faq.id}>
            <h4 className="faq-question__title">{faq.question}</h4>
            <p className="faq-question__answer">{faq.answer}</p>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default FaqPage;
