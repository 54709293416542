interface Props {
  color?: string;
  className?: string;
}

const ArrowLeftTailed = ({ color, className }: Props) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.2929 5.29289C9.6834 4.90237 10.3166 4.90237 10.7071 5.29289C11.0976 5.68342 11.0976 6.31658 10.7071 6.70711L6.4142 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H6.4142L10.7071 17.2929C11.0976 17.6834 11.0976 18.3166 10.7071 18.7071C10.3166 19.0976 9.6834 19.0976 9.2929 18.7071L3.2929 12.7071C2.9024 12.3166 2.9024 11.6834 3.2929 11.2929L9.2929 5.29289Z"
      fill={`var(--${color || "navy"})`}
    />
  </svg>
);

export default ArrowLeftTailed;
