import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./reducers/auth.slice";
import recentSheetsReducer from "./reducers/recentSheets.slice";
import freeContentReducer from "./reducers/freeContent.slice";
const persistConfig = {
  key: "root",
  whitelist: ["auth", "recentSheets", "categories", "sheets"],
  storage,
};
const combinedReducers = combineReducers({
  auth: userReducer,
  recentSheets: recentSheetsReducer,
  freeContent: freeContentReducer,
});

const persistedReducer = persistReducer(persistConfig, combinedReducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppStore = typeof store;
export type AppState = ReturnType<typeof store.getState>;
const dispatch = store.dispatch;
export type AppDispatch = typeof dispatch;

export default store;
