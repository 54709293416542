import api from "./api/axios";
import { categoryRedirection, sheetRedirection } from "./legacyRedirection";

const getOldSheetRedirectionOffline = async (
  legacySheetId: string,
  legacyCatId: string
) => {
  const sheetId = await sheetRedirection(legacySheetId, legacyCatId);
  if (sheetId) return sheetId;
  throw new Error("Sheet not found");
};

const getOldSheetRedirectionOnline = async (
  legacySheetId: string,
  legacyCatId: string
) => {
  try {
    const { data: sheetId } = await api.get<number[]>(
      `/sheet/legacy-redirection/${legacySheetId}/${legacyCatId}`
    );
    if (!sheetId) return;
    return sheetId;
  } catch (_) {}
};

const getOldCategoryRedirectionOffline = async (legacyCatId: string) => {
  const categoryId = await categoryRedirection(legacyCatId);
  if (categoryId) return categoryId;
  throw new Error("Category not found");
};

const getOldCategoryRedirectionOnline = async (legacyCatId: string) => {
  try {
    const { data: categoryId } = await api.get<number[]>(
      `/category/legacy-redirection/${legacyCatId}`
    );
    if (!categoryId) return;
    return categoryId;
  } catch (_) {}
};

export {
  getOldCategoryRedirectionOnline,
  getOldSheetRedirectionOnline,
  getOldCategoryRedirectionOffline,
  getOldSheetRedirectionOffline,
};
