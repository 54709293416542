import { FormEvent, useState } from "react";
import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { z } from "zod";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";

import api from "../../utils/api/axios";
import { notifyApiSuccess } from "../../utils/api/notifyApi";

import {
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from "../StyledDialog";

const schema = z.object({
  oldPassword: z
    .string({ message: "Ancien mot de passe requis" })
    .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
  newPassword: z
    .string({ message: "Nouveau mot de passe requis" })
    .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
  newPasswordConfirmation: z
    .string({ message: "Confirmation du nouveau mot de passe requis" })
    .min(8, "Le mot de passe doit contenir au moins 8 caractères"),
});

type FormData = z.infer<typeof schema>;

interface EditPasswordDialogProps {
  open: boolean;
  onClose: (refresh?: boolean) => void;
}

const EditPasswordDialog = ({ open, onClose }: EditPasswordDialogProps) => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] =
    useState(false);
  const [oldMdp, setOldMdp] = useState("");
  const [mdp, setMdp] = useState("");

  const { Field, Subscribe, handleSubmit } = useForm<
    FormData,
    typeof zodValidator
  >({
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      try {
        await api.post("/auth/change-password", {
          oldPassword: value.oldPassword,
          newPassword: value.newPassword,
        });
        notifyApiSuccess("Votre changement de mot de passe a été effectué");
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    },
  });

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleSubmit();
  };

  return (
    <StyledDialog open={open} onClose={() => onClose()} fullWidth>
      <form onSubmit={(e) => submit(e)}>
        <StyledDialogTitle
          sx={{
            fontSize: 20,
            fontWeight: 600,
            color: "var(--navy)",
            padding: "24px",
          }}
        >
          Réinitialisation du mot de passe
        </StyledDialogTitle>

        <StyledDialogContent>
          <Field
            name="oldPassword"
            validators={{ onChange: schema.shape.oldPassword }}
            children={(field) => (
              <div className="input-container">
                <TextField
                  size="small"
                  type={showOldPassword ? "text" : "password"}
                  label="Ancien mot de passe"
                  autoComplete="password"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                    setOldMdp(e.target.value);
                  }}
                  sx={{ marginTop: "5px" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          edge="end"
                        >
                          {showOldPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="newPassword"
            validators={{
              onChange: schema.shape.newPassword.refine((v) => v !== oldMdp, {
                message:
                  "Le nouveau mot de passe ne peut pas être le même que l'ancien",
              }),
            }}
            children={(field) => (
              <div className="input-container">
                <TextField
                  size="small"
                  type={showNewPassword ? "text" : "password"}
                  label="Nouveau mot de passe"
                  autoComplete="new-password"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => {
                    field.handleChange(e.target.value);
                    setMdp(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />

          <Field
            name="newPasswordConfirmation"
            validators={{
              onChange: schema.shape.newPasswordConfirmation.refine(
                (v) => v === mdp,
                {
                  message: "Les mots de passe ne correspondent pas",
                }
              ),
            }}
            children={(field) => (
              <div className="input-container">
                <TextField
                  size="small"
                  type={showNewPasswordConfirmation ? "text" : "password"}
                  label="Confirmation du nouveau mot de passe"
                  autoComplete="new-password"
                  fullWidth
                  value={field.state.value}
                  error={!!field.state.meta.errors.length}
                  onBlur={field.handleBlur}
                  onChange={(e) => field.handleChange(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowNewPasswordConfirmation(
                              !showNewPasswordConfirmation
                            )
                          }
                          edge="end"
                        >
                          {showNewPasswordConfirmation ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {field.state.meta.errors.length ? (
                  <em className="input-error">{field.state.meta.errors}</em>
                ) : null}
              </div>
            )}
          />
        </StyledDialogContent>

        <StyledDialogActions>
          <Button variant="outlined" onClick={() => onClose()}>
            Annuler
          </Button>
          <Subscribe
            // @ts-ignore ignore build error
            selector={(state) => [state.canSubmit, state.isSubmitting]}
            // @ts-ignore ignore build error
            children={([canSubmit, isSubmitting]) => (
              <Button
                type="submit"
                variant="contained"
                disabled={Boolean(!canSubmit) || Boolean(isSubmitting)}
              >
                Changer
              </Button>
            )}
          />
        </StyledDialogActions>
      </form>
    </StyledDialog>
  );
};

export default EditPasswordDialog;
